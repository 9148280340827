
import { defineComponent, reactive, toRefs } from "vue";
import { Api } from "@/assets/Api";
import { ElMessage, ElMessageBox } from "element-plus";

export default defineComponent({
  name: "AHBlackList",
  components: {},
  setup() {
    let data = reactive<any>({
      dialogFormVisible: false,
      addForm: {
        userphone: "",
        memo: "",
      },
      form: {
        userphone: "",
      },
      userList: [],
    });

    const selectuserblack = () => {
      if (data.form.userphone == "") {
        Api.Msg.warning("请输入用户号码");
        return;
      }
      var acc = { userphone: data.form.userphone };
      Api.Api2.AHselectuserblack(acc).then((res: any) => {
        console.info(res);
        if (res.code == "0") {
          data.userList = res.data;
        }
      });
    };

    const adduserblack = () => {
      Api.Api2.AHadduserblack(data.addForm).then((res: any) => {
        if (res.code == "0") {
          Api.Msg.success(res.resultMsg);
          data.addForm.userphone = "";
          data.addForm.memo = "";
          data.dialogFormVisible = false;
        } else {
          Api.Msg.warning(res.resultMsg);
        }
      });
    };

    const deleteuserblack = (row: any) => {
      ElMessageBox.confirm("确定要删除该用户号码吗？", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "",
      })
        .then(() => {
          Api.Api2.AHdeleteuserblack(row).then((res: any) => {
            if (res.code == "0") {
              Api.Msg.success(res.resultMsg);
              selectuserblack();
            } else {
              Api.Msg.warning(res.resultMsg);
            }
          });
        })
        .catch(() => {
          console.log("xx");
        });
    };

    return {
      ...toRefs(data),
      selectuserblack,
      adduserblack,
      deleteuserblack,
    };
  },
});
