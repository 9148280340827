import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_form, {
      inline: true,
      model: _ctx.form,
      class: "demo-form-inline"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form_item, { label: "用户号码" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: _ctx.form.userphone,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.userphone) = $event)),
              placeholder: "请输入用户号码"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_button, {
              type: "primary",
              onClick: _ctx.selectuserblack
            }, {
              default: _withCtx(() => [
                _createTextVNode("查询")
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_el_button, {
              type: "primary",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.dialogFormVisible = true))
            }, {
              default: _withCtx(() => [
                _createTextVNode("新增")
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model"]),
    _createVNode(_component_el_table, {
      ref: "table",
      data: _ctx.userList,
      border: "",
      style: {"width":"100%"},
      height: "80vh",
      "row-key": "UID",
      stripe: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          prop: "userphone",
          label: "用户号码"
        }),
        _createVNode(_component_el_table_column, {
          prop: "memo",
          label: "备注"
        }),
        _createVNode(_component_el_table_column, { label: "操作" }, {
          default: _withCtx((scope) => [
            _createVNode(_component_el_button, {
              size: "small",
              type: "primary",
              link: "",
              onClick: ($event: any) => (_ctx.deleteuserblack(scope.row))
            }, {
              default: _withCtx(() => [
                _createTextVNode("删除")
              ]),
              _: 2
            }, 1032, ["onClick"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["data"]),
    _createVNode(_component_el_dialog, {
      title: "新增黑名单",
      modelValue: _ctx.dialogFormVisible,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.dialogFormVisible) = $event))
    }, {
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_el_button, {
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.dialogFormVisible = false))
          }, {
            default: _withCtx(() => [
              _createTextVNode("取 消")
            ]),
            _: 1
          }),
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: _ctx.adduserblack
          }, {
            default: _withCtx(() => [
              _createTextVNode("确 定")
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_el_form, { model: _ctx.form }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              label: "用户号码",
              "label-width": "120px"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.addForm.userphone,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.addForm.userphone) = $event)),
                  type: "textarea",
                  rows: 2,
                  placeholder: "多个号码用“、”隔开",
                  autocomplete: "off"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "备注",
              "label-width": "120px"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.addForm.memo,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.addForm.memo) = $event)),
                  autocomplete: "off"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}